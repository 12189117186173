@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 1 999;
}

@tailwind utilities;

@layer base {
  .banner-alert {
    background-color: #f8b4d0;
    border-color: #FF579D;
    @apply flex p-4 mb-4 border-t-4 fixed top-16 w-full z-10 items-center justify-center;
  }

  .banner-alert-text {
    color: #B32B63;
  }

  .banner-alert-border {
    border-color: #B32B63;
  }

  .banner-alert-hover {
    background-color: #B32B63;
    color: #FFA2C9;
  }
}